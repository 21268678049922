<template>
  <v-row :no-gutters="detail.noGutters" :justify="detail.justify || 'start'">
    <v-col
      v-for="(node, index) in detail.nodes"
      :key="index"
      v-bind="getAttrs(node)"
      :id="node.key"
      :class="{
        'focus-node': focus(node.key)
      }"
    >
      <CmsBreadcrumb v-if="node.type === types.breadcrumb" :value="node" />
      <CmsVideo v-if="node.type === types.video" :value="node" />
      <CmsHtml v-if="node.type === types.html" :value="node" />
      <CmsImage v-if="node.type === types.image" :value="node" />
      <CmsDrive v-if="node.type === types.drive" :value="node" />
      <CmsButton v-if="node.type === types.button" :value="node" />
      <CmsButtonGroup v-if="node.type === types.buttonGroup" :value="node" />
      <CmsJson v-if="node.type === types.json" :value="node" />
      <CmsStyle v-if="node.type === types.style" :value="node" />
      <CmsLine v-if="node.type === types.line" :value="node" />
      <CmsForm v-if="node.type === types.form" :id="detail.id" :value="node" />
      <CmsGeojson v-if="node.type === types.geojson" :id="detail.id" :value="node" />
    </v-col>
  </v-row>
</template>

<script>
import { types } from './cms';
export default {
  props: {
    detail: {
      type: Object,
      required: true
    }
  },
  components: {
    CmsVideo: () => import('./Video.vue'),
    CmsHtml: () => import('./Html.vue'),
    CmsImage: () => import('./Image.vue'),
    CmsDrive: () => import('./Drive.vue'),
    CmsButton: () => import('./Button.vue'),
    CmsButtonGroup: () => import('./ButtonGroup.vue'),
    CmsJson: () => import('./Json.vue'),
    CmsStyle: () => import('./Style.vue'),
    CmsLine: () => import('./Line.vue'),
    CmsForm: () => import('./Form.vue'),
    CmsBreadcrumb: () => import('./Breadcrumb.vue'),
    CmsGeojson: () => import('./Geojson.vue')
  },
  data() {
    return {
      types
    };
  },
  mounted() {
    setTimeout(() => {
      this.processQuery();
    }, 1000);
  },
  methods: {
    focus(key) {
      const focus = (this.$route.query.focus || '')
        .split(',')
        .filter(i => i)
        .map(i => String(i).toLowerCase());
      return focus.includes(String(key || '').toLowerCase());
    },
    highlight(key) {
      const highlights = (this.$route.query.highlight || '')
        .split(',')
        .filter(i => i)
        .map(i => String(i).toLowerCase());
      return highlights.includes(String(key || '').toLowerCase());
    },
    getAttrs(node) {
      let attrs = {};
      let offsets = {};
      if (node && node.cols) {
        attrs = { ...node.cols };
      }
      if (node && node.offsets) {
        offsets = { ...node.offsets };
      }
      if (attrs.xs) {
        attrs.cols = attrs.xs;
      }
      if (offsets.xs) {
        attrs.offset = offsets.xs;
      }
      if (node.offsets) {
        Object.keys(node.offsets).forEach(offset => {
          attrs[`offset-${offset}`] = node.offsets[offset];
        });
      }
      attrs = { ...attrs };
      if (node.justify) {
        attrs.class = `d-flex justify-${node.justify}`;
      }
      return attrs;
    },
    focusNode() {
      const el = document.querySelector('.focus-node');
      if (!el) return;
      if (el) {
        el.scrollIntoView({ behavior: 'smooth' });
      }
    },
    highlightNodes() {
      this.detail?.nodes?.forEach(e => {
        if (this.highlight(e.key)) {
          const el = document.getElementById(e.key);
          el.classList.add('highlight-node');
          setTimeout(() => {
            el.classList.remove('highlight-node');
          }, 2000);
        }
      });
    },
    processQuery() {
      this.focusNode();
      setTimeout(() => {
        this.highlightNodes();
      }, 500);
    }
  }
};
</script>

<style scoped lang="scss">
.highlight-node {
  background-color: #feffc0;
}
</style>

import { getFirestore } from '../../services/firebase';

const privacy = {
  private: 'private',
  admin: 'admin',
  public: 'public'
};
const types = {
  video: 'video',
  html: 'html',
  page: 'page',
  json: 'json',
  image: 'image',
  drive: 'drive',
  button: 'button',
  // buttonGroup: 'buttonGroup',
  navigation: 'navigation',
  favicon: 'favicon',
  spacer: 'spacer',
  style: 'style',
  line: 'line',
  form: 'form',
  breadcrumb: 'breadcrumb',
  geojson: 'geojson'
};

const get = ({ key, url, type, privacy, user, admin }, callback) => {
  let q = getFirestore().collection('cms');
  if (key) {
    q = q.where('key', '==', key);
  }
  if (url) {
    q = q.where('url', '==', url);
  }
  if (type) {
    q = q.where('type', '==', type);
  }

  if (!privacy) {
    privacy = 'public';
  }

  if (privacy !== 'all') {
    q = q.where('privacy', '==', privacy);
    if (privacy === privacy.private) {
      q = q.where('user', '==', user || '');
    }
  }
  // if (privacy) {
  //   q = query(q, where('privacy', '==', privacy))
  // }
  if (callback) {
    return q.onSnapshot(callback);
  } else {
    return q.get();
  }
};

export { get, privacy, types };
